import { DROPDOWN_VALUES, PRICING_OPTIONS } from '../../helpers/constants';
import React, { Component } from 'react';
import LoginState from '../LoginState';
import { formatCurrency, formatPhoneNumber, numbersNoFormat } from '../../helpers/utils';

class LoanReview extends Component {
  isLO = this.props.contacts.role === 'Loan Officer';

  state = {
    validated: [],
    isSubmitting: false,
    doneSubmitting: false,
    responseMsg: '',
    loan: {
      loan: {
        programSelected: this.props.loan.selectedProgram,
        firstLoanAmount: formatCurrency(this.props.loan.amount),
        secondLoanAmount: formatCurrency(0),
        documentType:
          PRICING_OPTIONS.documentType[
            parseInt(this.props.loan.documentType) - 1
          ].label,
        LTV: this.props.loan.ltv + '%',
        CLTV: this.props.loan.ltv + '%',
        lienPosition:
          DROPDOWN_VALUES.lienPosition[this.props.loan.lienPosition],
        loanType: this.props.loan.product,
        loanPurpose:
          PRICING_OPTIONS.loanPurpose[parseInt(this.props.loan.purpose) - 1]
            .label,
        escrowWaiver:
          PRICING_OPTIONS.impound[parseInt(this.props.loan.impound) - 1].label,
        PMI: PRICING_OPTIONS.pmi[parseInt(this.props.loan.pmi) - 1].label,
        compensationPlan:
          parseInt(this.props.loan.compPlan) === 1 ? 'Borrower Paid' : 'Lender Paid (' + LoginState.compPlan + ')',
        businessChannel:
          LoginState.orgType??'Retail',
        eDisclosure:
          PRICING_OPTIONS.eDisclosure[parseInt(this.props.loan.eDisclosure) - 1]
            .label,
      },
      borrower: {
        name:
          this.props.loan.borrowers[0].firstName +
          ' ' +
          this.props.loan.borrowers[0].lastName,
        creditScore: this.props.loan.borrowers[0].fico,
        debtToIncome: this.props.loan.borrowers[0].dti + '%',
        ausOnly: this.props.loan.ausOnly === '1' ? 'Yes' : 'No',
      },
      property: {
        occupancyType:
          PRICING_OPTIONS.occupancyType[
            parseInt(this.props.loan.occupancyType) - 1
          ].label,
        propertyType:
          PRICING_OPTIONS.propertyType[
            parseInt(this.props.loan.propertyType) - 1
          ].label,
        appraisalValue: this.props.loan.property.appraisalValue,
        purchasePrice: this.props.loan.purchasePrice,
        propertyAddress:
          this.props.loan.property.streetAddress === '---'
            ? 'TBD'
            : this.props.loan.property.streetAddress +
              ' ' +
              this.props.loan.property.city +
              ' ' +
              this.props.loan.property.state +
              ' ' +
              this.props.loan.property.zipcode,
        propertyCounty: this.props.loan.property.county,
        propertyState: this.props.loan.property.state,
      },
      fees: {
        originationFee: this.props.loan.originationFee,
        adminFee: this.props.loan.adminFee,
        processingFee: this.props.loan.processingFee,
      },
    },
    ContactInfo: {
      broker: {
        name: this.props.contacts.originatorName ?? '---',
        email: this.props.contacts.originatorEmail ?? '---',
        phone: this.props.contacts.originatorPhone ?? '---',
      },
      processor1: {
        name: this.props.contacts.processor1Name ?? '---',
        email: this.props.contacts.processor1Email ?? '---',
        phone: this.props.contacts.processor1Phone ?? '---',
      },
      processor2: {
        name: this.props.contacts.processor2Name ?? '---',
        email: this.props.contacts.processor2Email ?? '---',
        phone: this.props.contacts.processor2Phone ?? '---',
      },
    },
    index: {
      name: 'full name',
      email: 'email address',
      phone: 'phone number',
    },
  };

  componentDidMount() {
    if (!this.state.ContactInfo.borrower1) {
      const newContactInfo = {
        ...this.state.ContactInfo,
      };
      this.props.loan.borrowers.forEach(
        (borrower, index) =>
          (newContactInfo[`borrower${index + 1}`] = {
            name: `${borrower.firstName} ${borrower.lastName}`,
            email: this.props.contacts[`borrower${index}`].email || '---',
            phone: this.props.contacts[`borrower${index}`].phone || '---',
          })
      );
      this.setState({
        ContactInfo: newContactInfo,
        isSubmitting: false
      });
    }
  }

  formatUserInputDataForServer() {
    var userInputs =
      '[LoanInfo]$$$$$' +
      'Program$$$$$' +
      this.state.loan.loan.programSelected +
      '$$$$$' +
      'First Loan$$$$$' +
      this.state.loan.loan.firstLoanAmount +
      '$$$$$' +
      'Second Loan$$$$$' +
      this.state.loan.loan.secondLoanAmount +
      '$$$$$' +
      'Documentation$$$$$' +
      this.state.loan.loan.documentType +
      '$$$$$' +
      'LTV/CLTV$$$$$' +
      this.state.loan.loan.LTV +
      '/' +
      this.state.loan.loan.CLTV +
      '$$$$$' +
      'Loan Type$$$$$' +
      this.state.loan.loan.loanType +
      '$$$$$' +
      'Loan Purpose$$$$$' +
      this.state.loan.loan.loanPurpose +
      '$$$$$' +
      'Occupancy Type$$$$$' +
      this.state.loan.property.occupancyType +
      '$$$$$' +
      'Property Type$$$$$' +
      this.state.loan.property.propertyType +
      '$$$$$' +
      'Escrow$$$$$' +
      this.state.loan.loan.escrowWaiver +
      '$$$$$' +
      'PMI$$$$$' +
      this.state.loan.loan.PMI +
      '$$$$$' +
      'Comp Plan$$$$$' +
      this.state.loan.loan.compensationPlan +
      '$$$$$' +
      'Channel$$$$$' +
      this.state.loan.loan.businessChannel +
      '$$$$$' +
      'eDisclosure$$$$$' +
      this.state.loan.loan.eDisclosure +
      '$$$$$' +
      'Lien Position$$$$$' +
      this.state.loan.loan.lienPosition +
      '$$$$$' +
      'Credit Score$$$$$' +
      this.state.loan.borrower.creditScore +
      '$$$$$' +
      'DTI$$$$$' +
      this.state.loan.borrower.debtToIncome +
      '$$$$$' +
      'AUS Only$$$$$' +
      this.state.loan.borrower.ausOnly +
      '$$$$$' +
      'Origination Fee$$$$$' +
      this.state.loan.fees.originationFee +
      '$$$$$' +
      'Admin Fee$$$$$' +
      this.state.loan.fees.adminFee +
      '$$$$$' +
      'Processing Fee$$$$$' +
      this.state.loan.fees.processingFee +
      '$$$$$';

    if (this.state.loan.loan.loanPurpose !== 'PUR')
      userInputs +=
        'Estimated Value$$$$$' +
        formatCurrency(this.props.loan.purchasePrice) +
        '$$$$$';
    else
      userInputs +=
        'Purchase Price$$$$$' +
        formatCurrency(this.props.loan.purchasePrice) +
        '$$$$$';

    userInputs += '[Contacts]$$$$$';
    // borrowers
    for (var i = 0; i < this.props.loan.borrowers.length; i++) {
      userInputs +=
        'Borrower ' +
        (i + 1) +
        '$$$$$' +
        this.props.loan.borrowers[i].firstName +
        ' ' +
        this.props.loan.borrowers[i].lastName +
        '$$$$$' +
        this.props.contacts['borrower' + i].email +
        '$$$$$' +
        this.props.contacts['borrower' + i].phone +
        '$$$$$';
    }
    // loan officer
    userInputs +=
      'Loan Officer$$$$$' +
      this.state.ContactInfo.broker.name +
      '$$$$$' +
      this.state.ContactInfo.broker.email +
      '$$$$$' +
      this.state.ContactInfo.broker.phone +
      '$$$$$';
    // processor 1
    userInputs +=
      'Processor #1$$$$$' +
      this.state.ContactInfo.processor1.name +
      '$$$$$' +
      this.state.ContactInfo.processor1.email +
      '$$$$$' +
      this.state.ContactInfo.processor1.phone +
      '$$$$$';

    // processor 2
    userInputs +=
      'Processor #2$$$$$' +
      this.state.ContactInfo.processor2.name +
      '$$$$$' +
      this.state.ContactInfo.processor2.email +
      '$$$$$' +
      this.state.ContactInfo.processor2.phone +
      '$$$$$';

    return userInputs;
  }

  async registerLoan() {
    this.setState({ isSubmitting: true });

    var propTypeCode;
    var units = 1;
    switch (this.props.loan.propertyType) {
      case '1': // SFR
        propTypeCode = '2';
        break;
      case '2': // Condo
        propTypeCode = '5';
        break;
      case '4': // 2 unit
        propTypeCode = '5';
        units = 2;
        break;
      case '5': // 3 unit
        propTypeCode = '5';
        units = 3;
        break;
      case '6': // 4 unit
        propTypeCode = '5';
        units = 4;
        break;
      case '3': // PUD
        propTypeCode = '6';
        break;
      default:
        propTypeCode = '0';
        break;
    }

    const params = {
      userID: LoginState.userId.toString(),

      originatorUserName: this.props.contacts.originatorUserName,
      processor1UserName: this.props.contacts.processor1UserName,
      processor2UserName: this.props.contacts.processor2UserName,
      userInputData: this.formatUserInputDataForServer(),
      fnmFile: this.props.fnmFile,
      originatorEmail: this.props.contacts.originatorEmail,
      processor1Email: this.props.contacts.processor1Email,
      processor2Email: this.props.contacts.processor2Email,
      ausOnly: this.props.loan.ausOnly === '1' ? '1' : '2',

      loanPurpose: parseInt(this.props.loan.purpose) === 1 ? '1' : '2',
      occupancyType: this.props.loan.occupancyType.toString(),
      noUnits: units.toString(),
      propertyType: propTypeCode.toString(),
      purchasePrice: numbersNoFormat(this.props.loan.purchasePrice.toString()),
      originationFee: numbersNoFormat(this.props.loan.originationFee),
      adminFee: numbersNoFormat(this.props.loan.adminFee),
      processingFee: numbersNoFormat(this.props.loan.processingFee),
    };

    fetch('/loansubmit', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(data => {
        // error check
        if (!data.startsWith('FileID:')) {
          // show error message
          this.setState({ errorSubmitting: data, isSubmitting: false });
          return;
        } else {
          const fileID = data.toString().split(':')[1];
          this.props.loan.fileID = fileID;
          this.props.changeStep('confirm', this.props.loan, null, null, null);
        }
      })
      .catch(e => console.log(e, 'Error Submitting File'));
  }

  formatLabel(label) {
    let str = label.replace(/([A-Z])/g, ' $1');
    let retVal = str.charAt(0).toUpperCase() + str.slice(1);

    if (
      retVal === 'Purchase Price' &&
      this.state.loan.loan.loanPurpose !== 'PUR'
    )
      return 'Estimated Value';
    else return retVal;
  }

  handleValidation = val => {
    const { validated } = this.state;
    const newValidated = validated
      ? validated.includes(val)
        ? validated.filter(el => el !== val)
        : [...validated, val]
      : [];
    this.setState({ validated: newValidated });
  };

  render() {
    if (this.state.isSubmitting) {
      return (
        <div className="text-center">
          <h5>Processing File Registration - Please Wait...</h5>
          <br />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
          </div>
        </div>
      );
    }

    if (this.state.errorSubmitting) {
      return (
        <div className="text-center">
          <h5>Error Occurred While Processing File Registration</h5>
          <br />
          {this.state.errorSubmitting}
        </div>
      );
    }

    return (
      <div className="row">
        <div className="text-center">
          <h5>
            Please Review ALL the Information Below
            <br />
            And Mark Each Checkbox "Verified" To Continue
          </h5>
        </div>
        <div className="col-12">
          {['borrower', 'property', 'loan', 'fees'].map(title => {
            return (
              <label
                className="border rounded d-flex gap-3 mb-2 bg-white p-2"
                key={title}
              >
                <input
                  className="form-check-input flex-shrink-0"
                  type="checkbox"
                  value=""
                  checked={this.state.validated.includes(title)}
                  onChange={() => this.handleValidation(title)}
                />
                <span className="w-100 form-checked-content">
                  <strong className="text-capitalize">
                    {title} {title !== 'fees' && 'Details'}
                  </strong>
                  {Object.entries(this.state.loan[title]).map(
                    ([label, value]) => (
                      <div className="row" key={label}>
                        <div className="col-6 text-end">
                          <p>{this.formatLabel(label).toUpperCase()}</p>
                        </div>
                        <div className="col-6 text-start">
                          <p>{value}</p>
                        </div>
                      </div>
                    )
                  )}
                </span>
              </label>
            );
          })}
        </div>
        <div className="col-12">
          <label className="border rounded d-flex gap-3 mb-2 bg-white p-2">
            <input
              className="form-check-input flex-shrink-0"
              type="checkbox"
              value=""
              checked={this.state.validated.includes('contact')}
              onChange={() => this.handleValidation('contact')}
            />
            <span className="w-100 form-checked-content">
              <strong className="text-capitalize mb-2">
                Contact Information
              </strong>
              {Object.entries(this.state.ContactInfo)
                .sort((a, b) =>
                  a[0].includes('borrower') && !b[0].includes('borrower')
                    ? -1
                    : 1
                )
                .map(([title, contact]) => (
                  <div className="col-12" key={title}>
                    <p className="text-gray border-gray border-bottom border-bottom-2 text-capitalize">
                      {title.includes('borrower') ? 'borrower' : title}
                    </p>
                    {Object.entries(contact).map(([label, value]) => (
                      <div className="row" key={label}>
                        <div className="col-6 text-end">
                          <p>{this.state.index[label].toUpperCase()}</p>
                        </div>
                        <div className="col-6 text-start">
                          <p>
                            {[
                              'firstLoanAmount',
                              'secondLoanAmount',
                              'appraisalValue',
                              'purchaseValue',
                            ].includes(value)
                              ? formatCurrency(value)
                              : label === 'phone'
                              ? formatPhoneNumber(value)
                              : value}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </span>
          </label>
        </div>
        <div className="col-12 text-end mt-4">
          <button
            className="btn btn-light"
            onClick={() =>
              this.props.changeStep(
                'contact',
                this.props.loan,
                this.props.fnmFile,
                null
              )
            }
          >
            Go Back
          </button>
          <button
            className="btn btn-primary text-white ms-2"
            disabled={this.state.validated.length < 5 || this.state.isSubmitting}
            onClick={() => this.registerLoan()}
          >
            Register
          </button>
        </div>
      </div>
    );
  }
}

export default LoanReview;
