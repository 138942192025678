import {
  Body,
  Verify,
  Task,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS } from '../../helpers/constants';
import { getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class QcChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      floodInsRequired: false,
      isLoading: true,
      errorMessage: '',
      checkList: {
        verifyEmail: { checked: false },
        verifyLicense: { checked: false },
        realEstateAgents: { checked: false },
        floodCert: { checked: false },
        invApproval: { checked: false },
        mers: { checked: false },
        vvoe: { checked: false },
        fraudReport: { checked: false },
        complete: { checked: false },
      },
    };

    this.getQcData();
  }

  async getQcData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getqcfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var qcFields = (await response).data;
    var statelist = {
      fileData: resultData,
      activeProperties: qcFields.activeProperties || '',
      originalActiveProperties: qcFields.activeProperties || '',
      letterDate: qcFields.letterDate || '',
      originalLetterDate: qcFields.letterDate || '',
      floodInsRequired: qcFields.floodInsurance === '1',
      voeType: qcFields.voeType === '1',
      isLoading: false,
    };

    // get checklist data
    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'QC' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    statelist['checkList'] = checklistData;
    this.setState(statelist);
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'QC-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'QC',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2], // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]],
          [fieldsToUpdate[i][4] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }
  
  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      // skip invApproval prop for conforming or flood ins not required
      if (
        prop === 'invApproval' &&
        !(
          this.state.fileData.programCode.includes('MTGP') &&
          this.state.floodInsRequired
        )
      )
        continue;
      // skip realEstateAgents if it's a refinance file
      if (
        prop === 'realEstateAgents' &&
        this.state.fileData.loanPurpose !== 'Purchase'
      )
        continue;
      if (
        prop === 'verifyEmail' &&
        !this.state.fileData.programCode.startsWith('MTGP1')
      )
        continue;
      visibleCount++;
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      )
        checkedCount++;
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('QC')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('QC')[0] +
        'QC (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('QC')[1]
          .substring(
            this.state.fileData.completePercent?.split('QC')[1].indexOf(')') + 1
          );
      this.props.updatePercent(
        'QC',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) {
      if(this.state.checkList.complete.checked == true) {
        this.state.checkList.complete.checked = false;
        this.setChecklistField('complete', false);
        await this.delay(500);
        this.props.updatePercent('QC', Math.round((checkedCount / visibleCount) * 100).toString());
      }
      return false;
    }
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('QC', 'complete');
  }

  async ClearAll() {
    await axios.get('/ClearCheckboxes', {
      params: { pFileID: this.state.activeFile, checklist: 'QC' },
    });
    this.setState({ showClearAllModal: false });
    this.getQcData();
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">QC Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">
          QC Checklist
          {isAdmin() && (
            <button className="btn btn-outline-secondary"
              style={{
                fontSize: '12px',
                height: '24px',
                padding: '2px',
                width: '70px',
                marginLeft: '15px',
                pointerEvents: 'auto',
                position: 'relative',
                top: '-2px'
              }}
              onClick={() => this.setState({showClearAllModal: true}) }
            > 
              Clear All
            </button>
          )}
        </h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Verify Borrower Email */}
          {fileData.programCode.startsWith('MTGP1') && (
            <ChecklistCard
              checked={checkList.verifyEmail.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="verifyEmail"
                    name="verifyEmail"
                    checked={checkList.verifyEmail.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="verifyEmail">
                    Borrower Email
                  </label>
                </div>
              }
              body={
                <div className="flex-grow-1 p-3 bg-white border border-start-0 rounded-end">
                  <div>
                    <Task />
                    Verify Email With Borrower Directly
                  </div>
                </div>
              }
              alert={
                checkList.verifyEmail.checked && (
                  <SignOff
                    user={checkList.verifyEmail.userName}
                    date={checkList.verifyEmail.timestamp}
                  />
                )
              }
            />
          )}

          {/* Verify MLO License */}
          <ChecklistCard
            checked={checkList.verifyLicense.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="verifyLicense"
                  name="verifyLicense"
                  checked={checkList.verifyLicense.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="verifyLicense">
                  Verify MLO License
                </label>
              </div>
            }
            body={
              <div>
                <Task />
                Input Expiration Date
              </div>
            }
            alert={
              checkList.verifyLicense.checked && (
                <SignOff
                  user={checkList.verifyLicense.userName}
                  date={checkList.verifyLicense.timestamp}
                />
              )
            }
          />

          {/* Real Estate Agents (Purchase Files) */}
          {this.state.fileData.loanPurpose === 'Purchase' && (
            <ChecklistCard
              checked={
                checkList.realEstateAgents && checkList.realEstateAgents.checked
              }
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="realEstateAgents"
                    name="realEstateAgents"
                    checked={
                      checkList.realEstateAgents &&
                      checkList.realEstateAgents.checked
                    }
                    onChange={this._toggleCheckbox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="realEstateAgents"
                  >
                    Verify RE License
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div>
                    <Verify />
                    Look Up Agents/Firms DRE License
                  </div>
                  <div className="hstack">
                    <Verify tip="Check That All Information Matches" />
                    <div>
                      Check License #, Address,
                      <br />
                      DBA (if applicable), Business
                    </div>
                  </div>
                </div>
              }
              alert={
                checkList.realEstateAgents &&
                checkList.realEstateAgents.checked && (
                  <SignOff
                    user={checkList.realEstateAgents.userName}
                    date={checkList.realEstateAgents.timestamp}
                  />
                )
              }
            />
          )}

          {/* Flood Cert */}
          <ChecklistCard
            checked={checkList.floodCert.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="floodCert"
                  name="floodCert"
                  checked={checkList.floodCert.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="floodCert">
                  Flood Cert
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task tip="Use Service Link" />
                  Pull Flood Determination
                </div>
                <div className="d-flex">
                  <Verify />
                  <TableZero>
                    <tbody>
                      <tr>
                        <td>Flood Zone Starts With "A" or "V":</td>
                      </tr>
                      <tr style={{ height: '10px' }} />
                      <tr>
                        <td>
                          <div className="form-check form-check-inline">
                            <label
                              className="form-check-label"
                              htmlFor="flood1"
                            >
                              <input
                                type="radio"
                                id="flood1"
                                name="flood"
                                className="form-check-input"
                                checked={this.state.floodInsRequired}
                                onChange={() => void 0}
                                disabled={checkList.floodCert.checked}
                                onClick={() => {
                                  if (!this.state.floodInsRequired) {
                                    this.setState({ floodInsRequired: true });
                                    this.updateDynamicDataField([
                                      ['Fld Ins', '', '1', '', ''],
                                    ]);
                                    checkList.floodCert.checked = false;
                                    this.setChecklistField('floodCert', false);
                                  }
                                }}
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <label
                              className="form-check-label"
                              htmlFor="flood2"
                            >
                              <input
                                type="radio"
                                id="flood2"
                                name="flood"
                                className="form-check-input"
                                checked={!this.state.floodInsRequired}
                                onChange={() => void 0}
                                disabled={checkList.floodCert.checked}
                                onClick={() => {
                                  if (this.state.floodInsRequired) {
                                    this.setState({ floodInsRequired: false });
                                    this.updateDynamicDataField([
                                      ['Fld Ins', '', '0', '', ''],
                                    ]);
                                    checkList.floodCert.checked = false;
                                    this.setChecklistField('floodCert', false);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </TableZero>
                </div>
              </div>
            }
            alert={
              checkList.floodCert.checked && (
                <SignOff
                  user={checkList.floodCert.userName}
                  date={checkList.floodCert.timestamp}
                />
              )
            }
          />

          {/* Investor Approval - Flood Zone (Non-QM) */}
          {fileData.programCode.includes('MTGP') &&
            this.state.floodInsRequired && (
              <ChecklistCard
                checked={checkList.invApproval.checked}
                label={
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      aria-label="Checkbox for following text input"
                      id="invApproval"
                      name="invApproval"
                      checked={checkList.invApproval.checked}
                      onChange={this._toggleCheckbox}
                    />
                    <label className="form-check-label" htmlFor="invApproval">
                      Investor Approval
                    </label>
                  </div>
                }
                body={
                  <div>
                    <Task tip="Get Investor Approval For Property In Flood Zone" />
                    Flood Zone Approval
                  </div>
                }
                alert={
                  checkList.invApproval.checked && (
                    <SignOff
                      user={checkList.invApproval.userName}
                      date={checkList.invApproval.timestamp}
                    />
                  )
                }
              />
            )}

          {/* MERS Property Search */}
          <ChecklistCard
            checked={checkList.mers.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="mers"
                  name="mers"
                  disabled={
                    !this.state.activeProperties ||
                    this.state.originalActiveProperties !==
                      this.state.activeProperties
                  }
                  checked={checkList.mers.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="mers">
                  MERS Search
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task />
                  Look Up Borrower(s) SSN on MERS Website
                </div>
                <div className="input-group">
                  <span className="input-group-text">Active Properties:</span>
                  <input
                    type="number"
                    name="activeProperties"
                    className="form-control"
                    value={this.state.activeProperties || ''}
                    onChange={this.captureInput}
                    disabled={checkList.mers.checked}
                  />
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={
                      this.state.originalActiveProperties ===
                        this.state.activeProperties || checkList.mers.checked
                    }
                    onClick={() =>
                      this.updateDynamicDataField([
                        [
                          'Active Properties',
                          '',
                          this.state.activeProperties,
                          '',
                          'activeProperties',
                        ],
                      ])
                    }
                  >
                    {this.state.activePropertiesUpdated ? '✓' : 'Update'}
                  </button>
                </div>
              </div>
            }
            alert={
              checkList.mers.checked && (
                <SignOff
                  user={checkList.mers.userName}
                  date={checkList.mers.timestamp}
                />
              )
            }
          />

          {/* VVOE */}
          <ChecklistCard
            checked={checkList.vvoe.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="vvoe"
                  name="vvoe"
                  checked={checkList.vvoe.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="vvoe">
                  VVOE
                </label>
              </div>
            }
            body={
              <React.Fragment>
                {fileData.programCode.includes('MTGP') /* Non-QM */ ? (
                  <div className="vstack gap-3">
                    {/* selection between WVOE or SE */}
                    <div className="d-flex">
                      <div>
                        <Verify tip="Check If Written VOE or Self Employed" />
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor="voeType1"
                          >
                            <input
                              type="radio"
                              id="voeType1"
                              name="voeType"
                              className="form-check-input"
                              checked={!this.state.voeType}
                              onChange={() => void 0}
                              disabled={checkList.vvoe.checked}
                              onClick={() => {
                                if (this.state.voeType) {
                                  this.setState({ voeType: false });
                                  this.updateDynamicDataField([
                                    ['VOE Type', '', '0', '', ''],
                                  ]);
                                  checkList.vvoe.checked = false;
                                  this.setChecklistField('vvoe', false);
                                }
                              }}
                            />
                            Wage Earner
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor="voeType2"
                          >
                            <input
                              type="radio"
                              id="voeType2"
                              name="voeType"
                              className="form-check-input"
                              checked={this.state.voeType}
                              onChange={() => void 0}
                              disabled={checkList.vvoe.checked}
                              onClick={() => {
                                if (!this.state.voeType) {
                                  this.setState({ voeType: true });
                                  this.updateDynamicDataField([
                                    ['VOE Type', '', '1', '', ''],
                                  ]);
                                  checkList.vvoe.checked = false;
                                  this.setChecklistField('vvoe', false);
                                }
                              }}
                            />
                            Self Employed
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <hr />
                    </div>
                    {!this.state.voeType ? (
                      <React.Fragment>
                        {/* for Wage Earner */}
                        <div>
                          <Verify />
                          Prior to CTC
                        </div>
                        <div>
                          <Task tip="Should Confirm With Someone Of Higher Position Than Borrower (HR or CEO/Owner)" />
                          Verify Employment With Employer
                        </div>
                        <div>
                          <Task />
                          Complete VVOE Form
                        </div>
                        <div>
                          <Task />
                          Clear VVOE Condition
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {/* for Self Employed */}
                        <div>
                          <Verify />
                          Prior to CTC
                        </div>
                        <div>
                          <Task
                            tip={
                              'Letter Date Within 30 Days From Scheduled Funding Date\n( If Not, Notify Underwriting )'
                            }
                          />
                          Check Date of 3rd Party Verifier Letter
                        </div>
                        <div className="input-group">
                          <span className="input-group-text">Letter Date:</span>
                          <input
                            type="date"
                            name="letterDate"
                            className="form-control"
                            value={this.state.letterDate || ''}
                            onChange={this.captureInput}
                            disabled={checkList.vvoe.checked}
                          />
                          <button
                            className="btn btn-primary btn-sm"
                            disabled={
                              this.state.originalLetterDate ===
                                this.state.letterDate || checkList.vvoe.checked
                            }
                            onClick={() =>
                              this.updateDynamicDataField([
                                [
                                  'Letter Date',
                                  '',
                                  this.state.letterDate,
                                  '',
                                  'letterDate',
                                ],
                              ])
                            }
                          >
                            {this.state.letterDateUpdated ? '✓' : 'Update'}
                          </button>
                        </div>
                        <div>
                          <Task />
                          Complete VVOE Form
                        </div>
                        <div>
                          <Task />
                          Clear VVOE Condition
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                ) : (
                  /* QM */
                  <div className="vstack gap-3">
                    <div>
                      <Verify />
                      Prior to CTC
                    </div>
                    <div>
                      <Task tip="Should Confirm With Someone Of Higher Position Than Borrower (HR or CEO/Owner)" />
                      Verify Employment With Employer
                    </div>
                    <div>
                      <Task />
                      Complete VVOE Form
                    </div>
                    <div>
                      <Task />
                      Clear VVOE Condition
                    </div>
                  </div>
                )}
              </React.Fragment>
            }
            alert={
              checkList.vvoe.checked && (
                <SignOff
                  user={checkList.vvoe.userName}
                  date={checkList.vvoe.timestamp}
                />
              )
            }
          />

          {/* Fraud Report */}
          <ChecklistCard
            checked={checkList.fraudReport && checkList.fraudReport.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="fraudReport"
                  name="fraudReport"
                  checked={
                    checkList.fraudReport && checkList.fraudReport.checked
                  }
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="fraudReport">
                  Fraud Report
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify />
                  Check if Assigned Appraiser Info Is In Byte
                </div>
                <div className="hstack">
                  <Task />
                  Pull Fraud Report
                </div>
              </div>
            }
            alert={
              checkList.fraudReport &&
              checkList.fraudReport.checked && (
                <SignOff
                  user={checkList.fraudReport.userName}
                  date={checkList.fraudReport.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
          <ModalConfirm
            show={this.state.showClearAllModal}
            onAccept={() => this.ClearAll()}
            onDecline={() => this.setState({ showClearAllModal: false })}
          >
            <div style={{ fontSize: '16px', textAlign: 'center' }}>
              <b>Are You Sure You Want to Clear <u>ALL</u> <br/>
              Checkbox Fields For This Checklist?</b>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
