import 'react-dropzone-uploader/dist/styles.css';
import React, { Component } from 'react';
import { base64ArrayBuffer } from '../helpers/utils';
import Dropzone from 'react-dropzone-uploader';
import Toast from '../components/Toast';
import styled from 'styled-components';
import ModalWait from '../components/ModalWait';

const FilesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  flex-direction: column;
`;

const DZInputContainer = styled.div`
  .dzu-inputLabelWithFiles {
    display: none;
  }
`;

const Layout = ({ input, dropzoneProps }) => (
  <div>
    <DZInputContainer {...dropzoneProps}>{input}</DZInputContainer>
  </div>
);

const MAX_FILE_SIZE = 20000000;

class UploadPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      successMessage: '',
      errorMessage: '',
      loan: '',
      fileArr: [],
    };
  }

  async submitFileToServer() {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    // check to see that combined file size is not over limit
    var totalSize = 0;
    for (var i = 0; i < this.state.fileArr.length; i++) {
      totalSize += this.state.fileArr[i].size;
    }
    if (totalSize >= MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'Combined File Size Exceeds 20Mb!' });
      return;
    }

    this.setState({ isUploading: true });

    // first combine all the pdfs into one.
    var combinedFiles = null;
    for (var j = 0; j < this.state.fileArr.length; j++) {
      if (j === 0) combinedFiles = this.state.fileArr[j].fileUIntArr;
      else
        combinedFiles = this.combinePDFBuffers(
          combinedFiles,
          this.state.fileArr[j].fileUIntArr
        );
    }
    // then submit to server
    const params = {
      docName: this.state.fileArr[0].fileName,
      fileContent: combinedFiles,
    };

    fetch('/uploadPdfTemplate', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(data => {
        // error check
        if (!data.startsWith('Success')) {
          // show error message
          this.setState({
            isUploading: false,
            successMessage: '',
            errorMessage: data,
          });
        } else {
          // notify for successful upload
          this.setState({
            isUploading: false,
            fileArr: [],
            successMessage: 'Template Successfully Uploaded!',
          });
        }
      });
  }

  combinePDFBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  InputContent() {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <div>
          <i
            className="bi bi-cloud-upload text-muted"
            style={{ fontSize: '4rem' }}
          ></i>
          <p className="mb-1 text-muted">
            Drag and Drop .PDF Files or Click Below to Upload
          </p>
          <small className="text-muted">
            (The System Will Only Accept Up to 20 Mb of Files at a Time)
          </small>
        </div>
      );
    }
  }

  handleChangeStatus({ file, remove }, status) {
    if (file && file.size > MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Cannot Exceed 20 Mb!' });
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        var file = [];
        file.push({
          fileUIntArr: uintarr,
          fileName: file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        this.setState({ fileArr: file });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  render() {
    return (
      <div className="container">
        <h4>Upload PDF Template</h4>
        <div className="text-center">
          <h5>Drag and Drop Pdf Files Or Click Below To Upload</h5>
          <div>
            (The System Will Only Accept Up to 20 Mb of Files at a Time)
          </div>
          <br />
          <Dropzone
            maxFiles="1"
            maxSizeBytes="20000000" // 20 Mb
            accept=".pdf"
            onChangeStatus={this.handleChangeStatus.bind(this)}
            LayoutComponent={Layout}
            inputContent={this.InputContent.bind(this)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                border: '2px dashed lightgray',
                height: '200px',
                borderRadius: '0.25rem',
              },
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (_, extra) => (extra.reject ? { color: 'red' } : {}),
            }}
          />
          <br />

          <FilesContainer>
            {this.state.fileArr.map(({ fileName }) => (
              <div key={fileName}>
                <img
                  src="/images/arrow.png"
                  width="10px"
                  height="10px"
                  alt=""
                />
                &nbsp;
                {fileName}
              </div>
            ))}
          </FilesContainer>

          {this.state.fileArr.length > 0 && (
            <div>
              <button
                className="btn btn-light"
                onClick={() => this.setState({ fileArr: [] })}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                id="findFileBtn"
                disabled={this.state.isUploading}
                onClick={() => this.submitFileToServer()}
              >
                Submit File
              </button>
            </div>
          )}

          {this.state.successMessage && (
            <Toast
              type="success"
              title={this.state.successMessage}
              onClose={() => this.setState({ successMessage: '' })}
            />
          )}
          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
          <ModalWait
            title="Uploading File. Please Wait!"
            show={this.state.isUploading}
          />
        </div>
      </div>
    );
  }
}
export default UploadPdf;
