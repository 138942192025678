import {
  Body,
  Verify,
  Task,
  Email,
  AutoCheck,
  SignOff,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS } from '../../helpers/constants';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class LockChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      borrowers: [],
      isLoading: true,
      cicCount: 0,
      errorMessage: '',
      checkList: {
        lockEmail: { checked: false },
        requestedRate: { checked: false },
        foreignNational: { checked: false },
        byteLocked: { checked: false },
        pnlPrep: { checked: false },
        ppp: { checked: false },
        incomeType: { checked: false },
        confirmLock: { checked: false },
        confirmationEmail: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      lockEmailLog: [],
      confirmationEmailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getLockData();
  }

  async getLockData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'LCK' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getlockfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var lockFields = (await response).data;
    this.setState({
      fileData: resultData,
      cicCount: parseInt(lockFields.cicCount),
      lockedDate: lockFields.lockedDate || '---',
      selfEmployed: lockFields.selfEmployed !== '0',
      byteRate: lockFields.byteRate || '---',
      requestedRate: lockFields.requestedRate || '',
      originalRequestedRate: lockFields.requestedRate || '',
      pnlPreparer: lockFields.pnlPreparer,
      prepaymentTerm: lockFields.prepaymentTerm,
      incomeVerify: lockFields.incomeVerify,
      lockConfirmed: lockFields.lockConfirmed === '1',
      isForeignNat: lockFields.foreignNational,
      lockTemplate: lockFields.lockTemplate,
      lockEmailLog: lockFields.lockEmailLog,
      lockConfirmationTemplate: lockFields.lockConfirmationTemplate,
      confirmationEmailLog: lockFields.confirmationEmailLog,
      isLoading: false,
    });
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'LCK-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'LCK',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        if (fieldsToUpdate[i][4]) {
          this.setState({
            ['original' +
            fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
            fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
              .toString()
              .trim(),
            [fieldsToUpdate[i][4] + 'Updated']: true,
          });
        }
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  pnlPreparerStr() {
    switch(this.state.pnlPreparer) {
      case "0":
        return "CPA Prepared";
      case "1":
        return "Borrower Prepared";
      default:
        return "---";
    }
  }

  incomeTypeStr() {
    switch(this.state.incomeVerify) {
      case "0":
        return "P&L Statement";
      case "1":
        return "Bank Statements";
      case "2":
        return "1099 Form";
      default:
        return "---";
    }
  }

  async confirmRateLock() {
    var response = axios.get('/confirmRateLock', {
      params: { pFileID: this.state.activeFile, pUserName: LoginState.userName },
    });
    var resultData = (await response).data;
    if (resultData !== 'Success') {
      console.log("Error Confirming Rate Lock");
    }
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'Doc Out':
          file.docOutDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }
  
  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') continue;
      if (prop === 'pnlPrep' && (!this.state.fileData.programCode.includes('MTGP1') || !this.state.selfEmployed)) continue;
      if (prop === 'ppp' && (!this.state.fileData.programCode.includes('MTGP2') || this.state.fileData.occupancyType !== "Investment")) continue;
      if (prop === 'incomeType' && (!this.state.fileData.programCode.includes('MTGP2') || !this.state.selfEmployed)) continue;
      if (prop === 'foreignNational' && !this.state.fileData.programCode.includes('MTGP1')) continue;
      visibleCount++;
      if (prop === 'byteLocked' && this.state.lockedDate !== '---') checkedCount++;
      if (
        prop === 'requestedRate' &&
        parseFloat(this.state.byteRate) ===
          parseFloat(this.state.originalRequestedRate)
      )
        checkedCount++;
      if (prop === 'confirmLock' && this.state.lockConfirmed && this.state.cicCount === 0)
        checkedCount++;
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      ) {
        checkedCount++;
      }
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('LCK')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('LCK')[0] +
        'LCK (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('LCK')[1]
          .substring(
            this.state.fileData.completePercent?.split('LCK')[1].indexOf(')') +
              1
          );
      this.props.updatePercent(
        'LCK',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) {
      if(this.state.checkList.complete.checked == true) {
        this.state.checkList.complete.checked = false;
        this.setChecklistField('complete', false);
        await this.delay(500);
        this.props.updatePercent('LCK', Math.round((checkedCount / visibleCount) * 100).toString());
      }
      return false;
    }
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('LCK', 'complete');
  }

  async ClearAll() {
    await axios.get('/ClearCheckboxes', {
      params: { pFileID: this.state.activeFile, checklist: 'LCK' },
    });
    this.setState({ showClearAllModal: false });
    this.getLockData();
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <>
          <h1 className="h5">Lock Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </>
      );
    }
    return (
      <>
        <h1 className="h5">
          Lock Checklist
          {isAdmin() && (
            <button className="btn btn-outline-secondary"
              style={{
                fontSize: '12px',
                height: '24px',
                padding: '2px',
                width: '70px',
                marginLeft: '15px',
                pointerEvents: 'auto',
                position: 'relative',
                top: '-2px'
              }}
              onClick={() => this.setState({showClearAllModal: true}) }
            > 
              Clear All
            </button>
          )}
        </h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Byte Locked */}
          <ChecklistCard
            checked={checkList.byteLocked.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="byteLocked"
                  name="byteLocked"
                  checked={this.state.lockedDate != '---'}
                />
                <label className="form-check-label" htmlFor="byteLocked">
                  Locked Date
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task />
                  {this.state.lockedDate != '---' ? 
                    <span>Rate Locked on {this.state.lockedDate}</span> : 
                    <span>Set Locked Date in Byte</span>
                  }
                </div>
              </div>
            }
            alert={
              <AutoCheck passed={this.state.lockedDate != '---'} />
            }
          />

          {/* Requested Rate */}
          <ChecklistCard
            checked={
              parseFloat(this.state.byteRate) ===
              parseFloat(this.state.originalRequestedRate)
            }
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="requestedRate"
                  id="requestedRate"
                  checked={
                    parseFloat(this.state.byteRate) ===
                    parseFloat(this.state.originalRequestedRate)
                  }
                />
                <label className="form-check-label" htmlFor="requestedRate">
                  Requested Rate
                </label>
              </div>
            }
            body={
              <TableZero>
                <tbody>
                  <tr>
                    <td>
                      <div className="input-group">
                        <span className="input-group-text">
                          Requested Rate:
                        </span>
                        <input
                          type="number"
                          name="requestedRate"
                          placeholder="%"
                          className="form-control"
                          value={this.state.requestedRate || ''}
                          onChange={this.captureInput}
                          disabled={
                            parseFloat(this.state.byteRate) ===
                            parseFloat(this.state.originalRequestedRate)
                          }
                        />
                        <button
                          className="btn btn-primary btn-sm"
                          disabled={
                            (!isAdmin() &&
                              this.state.originalRequestedRate ===
                                this.state.requestedRate) ||
                            parseFloat(this.state.byteRate) ===
                              parseFloat(this.state.originalRequestedRate)
                          }
                          onClick={() =>
                            this.updateDynamicDataField([
                              [
                                'Requested Rate',
                                '',
                                this.state.requestedRate,
                                'requestedRate',
                                '',
                              ],
                            ])
                          }
                        >
                          {this.state.requestedRateUpdated ? '✓' : 'Update'}
                        </button>{' '}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </TableZero>
            }
            alert={
              <AutoCheck
                passed={
                  parseFloat(this.state.byteRate) ===
                  parseFloat(this.state.originalRequestedRate)
                }
                tip={
                  'Requested Rate ( ' +
                  this.state.originalRequestedRate +
                  ' )%\nDoes Not Match\nByte Rate ( ' +
                  this.state.byteRate +
                  ' )%'
                }
              />
            }
          />

          {/* Income Type */}
          {(fileData.programCode.includes('MTGP2') && this.state.selfEmployed) && (
          <ChecklistCard
            checked={checkList.incomeType.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="incomeType"
                  name="incomeType"
                  disabled={!this.state.incomeVerify}
                  checked={checkList.incomeType.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="incomeType">
                  Income Type
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      <span>
                        For MTGP2 Programs, If Self-Employed
                        <br />
                        We Store Information For How The Income 
                        <br />
                        Will Be Verified From The UW Checklist
                      </span>
                    }
                  />
                  {this.state.incomeVerify ? 
                    this.incomeTypeStr() : 
                    <span style={{color:'red'}}>Not Specified Yet (UW Checklist)</span>
                  }
                </div>
              </div>
            }
            alert={
              checkList.incomeType.checked && (
                <SignOff
                  user={checkList.incomeType.userName}
                  date={checkList.incomeType.timestamp}
                />
              )
            }
          />
          )}

          {/* Pre-pay Penalty */}
          {(fileData.programCode.includes('MTGP2') && fileData.occupancyType === "Investment") && (
          <ChecklistCard
            checked={checkList.ppp.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="ppp"
                  name="ppp"
                  checked={checkList.ppp.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="ppp">
                  Prepay Penalty
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      <span>
                        For MTGP2 Program Investment Properties
                        <br />
                        We Need to Double Check the Prepayment Term 
                        <br />
                        As It Will Affect Loan Pricing
                      </span>
                    }
                  />
                  Prepayment Penalty Term ( {this.state.prepaymentTerm === '---' ? 'None' : ((Number(this.state.prepaymentTerm) / 12) + ' Year')} )
                </div>
              </div>
            }
            alert={
              checkList.ppp.checked && (
                <SignOff
                  user={checkList.ppp.userName}
                  date={checkList.ppp.timestamp}
                />
              )
            }
          />
          )}

          {/* Foreign National */}
          {fileData.programCode.includes('MTGP1') && (
          <ChecklistCard
            checked={checkList.foreignNational.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="foreignNational"
                  name="foreignNational"
                  checked={checkList.foreignNational.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="foreignNational">
                  Foreign National
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip={'For MTGP1 Programs Only'} /> Confirm Borrower {this.state.isForeignNat === "1" ? (<b><u>IS</u></b>) : (<b><u>IS NOT</u></b>)} a Foreign National
                </div>
              </div>
            }
            alert={
              checkList.foreignNational.checked && (
                <SignOff
                  user={checkList.foreignNational.userName}
                  date={checkList.foreignNational.timestamp}
                />
              )
            }
          />
          )}

          {/* P&L Preparer */}
          {(fileData.programCode.includes('MTGP1') && this.state.selfEmployed) && (
          <ChecklistCard
            checked={checkList.pnlPrep.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="pnlPrep"
                  name="pnlPrep"
                  disabled={!this.state.pnlPreparer}
                  checked={checkList.pnlPrep.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="pnlPrep">
                  P&L Preparer
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify
                    tip={
                      <span>
                        For MTGP1 Programs, If Self-Employed
                        <br />
                        We Store Information For Who Will Prepare 
                        <br />
                        The P&L Statement Used For Income Verification
                      </span>
                    }
                  />
                  {this.state.pnlPreparer ? 
                    this.pnlPreparerStr() : 
                    <span style={{color:'red'}}>Not Specified Yet (UW Checklist)</span>
                  }
                </div>
              </div>
            }
            alert={
              checkList.pnlPrep.checked && (
                <SignOff
                  user={checkList.pnlPrep.userName}
                  date={checkList.pnlPrep.timestamp}
                />
              )
            }
          />
          )}

          {/* Confirm Rate Lock */}
          <ChecklistCard
            checked={this.state.lockConfirmed && this.state.cicCount === 0}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="confirmLock"
                  id="confirmLock"
                  checked={this.state.lockConfirmed && this.state.cicCount === 0}
                  disabled={true}
                />
                <label className="form-check-label" htmlFor="confirmLock">
                  Confirm Rate Lock
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task tip={'Click Button to Start Showing the Rate Lock Table\nOn the Rate Lock Page and Generate the Lock History PDF'} />
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    /* disabled={this.state.lockConfirmed && this.state.cicCount === 0} */
                    onClick={() => {
                      this.updateDynamicDataField([
                        [
                          'Lock Confirmed',
                          '',
                          '1',
                          'confirmLock',
                          '',
                        ],
                      ]);
                      this.updateDynamicDataField([
                        [
                          'CIC Count',
                          '',
                          '0',
                          '',
                          '',
                        ],
                      ]);
                      this.setState({lockConfirmed: true, cicCount: 0});
                      this.confirmRateLock();
                    }}
                  >
                    Confirm/Update Lock + Generate PDF
                  </button>
                  {this.state.cicCount !== 0 && (
                    <div style={{marginTop:'10px', marginLeft:'10px', color:'red', fontSize:'12px'}}>
                      (<b>{this.state.cicCount}</b>) CIC Requests Require a RateLock Table Update
                    </div>
                  )}
                </div>
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.lockConfirmed && this.state.cicCount === 0}
                tip={'Rate Lock Table Visible On the Rate Lock Page\nAnd Lock History PDF Generated'}
              />
            }
          />

          {/* Confirmation Email */}
          <ChecklistCard
            checked={checkList.confirmationEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="confirmationEmail"
                  name="confirmationEmail"
                  checked={checkList.confirmationEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="confirmationEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Rate Locked (Confirmation)
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.lockConfirmationTemplate,
                      emailLog: this.state.confirmationEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.confirmationEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.confirmationEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.confirmationEmail.checked && (
                <SignOff
                  user={checkList.confirmationEmail.userName}
                  date={checkList.confirmationEmail.timestamp}
                />
              )
            }
          />

          {/* Lock Email */}
          <ChecklistCard
            checked={checkList.lockEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="lockEmail"
                  name="lockEmail"
                  checked={checkList.lockEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="lockEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Rate Locked (Compliance)
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.lockTemplate,
                      emailLog: this.state.lockEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.lockEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.lockEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.lockEmail.checked && (
                <SignOff
                  user={checkList.lockEmail.userName}
                  date={checkList.lockEmail.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
          <ModalConfirm
            show={this.state.showClearAllModal}
            onAccept={() => this.ClearAll()}
            onDecline={() => this.setState({ showClearAllModal: false })}
          >
            <div style={{ fontSize: '16px', textAlign: 'center' }}>
              <b>Are You Sure You Want to Clear <u>ALL</u> <br/>
              Checkbox Fields For This Checklist?</b>
            </div>
          </ModalConfirm>
        </div>
      </>
    );
  }
}
