import React, { Component } from 'react';
import axios from 'axios';
import LoginState from './LoginState';
import 'react-dropzone-uploader/dist/styles.css';
import { base64ArrayBuffer } from '../helpers/utils';
import Dropzone from 'react-dropzone-uploader';
import Toast from '../components/Toast';
import styled from 'styled-components';
import ModalWait from '../components/ModalWait';
import { withRouter } from 'react-router-dom';

const FilesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  flex-direction: column;
`;

const DZInputContainer = styled.div`
  .dzu-inputLabelWithFiles {
    display: none;
  }
`;

const Layout = ({ input, dropzoneProps }) => (
  <div>
    <DZInputContainer {...dropzoneProps}>{input}</DZInputContainer>
  </div>
);

const MAX_FILE_SIZE = 20000000;

class Submission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registeredData: '',
      isSubmitting: false,
      successMessage: '',
      errorMessage: '',
      loan: '',
      fileArr: [],
      searchLog: [],
    };
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id);
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ loan: '' });
    this.GetSearchLog(fileID);
    this.GetRegisteredData(fileID);
    this.GetSummaryData(fileID);
  }

  // get data before page loads
  GetRegisteredData = async fileID => {
    axios
      .get('/getregistereddata', {
        params: {
          pFileID: fileID,
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({ registeredData: res.data });
        }
      })
      .catch(e => console.log(e, 'Error fetching loan details'));
  };

  async GetSummaryData(fileID) {
    axios
      .get('/getloansummary', {
        params: {
          pFileID: fileID,
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({ loan: res.data });
        }
      })
      .catch(e => console.log(e, 'Error fetching loan details'));
  }

  GetDisplayRow(field, value) {
    if (field === 'Program') {
      return (
        <div className="col-12 col-md-3">
          <small className="text-muted">{field}</small>
          <p>{value}</p>
        </div>
      );
    } else {
      return (
        <div className="col-12 col-md-3">
          <small className="text-muted">{field}</small>
          <p>{value}</p>
        </div>
      );
    }
  }

  GetHeaderRow(title) {
    return (
      <div>
        <br />
        <h3 className="border-bottom-2 border-gray text-capitalize my-4">
          {title}
        </h3>
      </div>
    );
  }

  GetContactRow(title, name, email, phone, count) {
    if (!title) return <div />;
    const colors = {
      'Borrower 1': 'primary',
      'Borrower 2': 'primary',
      'Loan Officer': 'success',
      'Processor #1': 'info',
      'Processor #2': 'info',
    };
    return (
      <div className="g-col-12 g-col-md-4">
        <div className="card p-3 h-100">
          <div
            className={`feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3 bg-light border border-${colors[title]}`}
          >
            <i className={`bi bi-person text-${colors[title]}`}></i>
          </div>
          <h4 className="fw-semibold mb-0">{name || 'Unassigned'}</h4>
          <p className="text-muted mb-2">
            <i className="bi bi-person-badge" style={{ fontSize: '1rem' }}></i>{' '}
            {title || 'Unassigned'}
          </p>
          {email ? (
            <a href={`mailto:${email}`} className="text-muted mb-1">
              <i className="bi bi-at" style={{ fontSize: '1rem' }}></i> {email}
            </a>
          ) : (
            <p className="text-muted mb-1">
              <i className="bi bi-at" style={{ fontSize: '1rem' }}></i>{' '}
              Unassigned
            </p>
          )}
          <p className="text-muted mb-1">
            <i className="bi bi-telephone" style={{ fontSize: '1rem' }}></i>{' '}
            {phone
              ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
                  6,
                  10
                )}`
              : 'Unassigned'}
          </p>
        </div>
      </div>
    );
  }

  async submitFileToServer() {
    // skip if no files uploaded
    if (this.state.fileArr.length === 0) return;

    // check to see that combined file size is not over limit
    var totalSize = 0;
    for (var i = 0; i < this.state.fileArr.length; i++) {
      totalSize += this.state.fileArr[i].size;
    }
    if (totalSize >= MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'Combined File Size Exceeds 20Mb!' });
      return;
    }

    this.setState({ isUploading: true });

    // first combine all the pdfs into one.
    var combinedFiles = null;
    for (var j = 0; j < this.state.fileArr.length; j++) {
      if (j === 0) combinedFiles = this.state.fileArr[j].fileUIntArr;
      else
        combinedFiles = this.combinePDFBuffers(
          combinedFiles,
          this.state.fileArr[j].fileUIntArr
        );
    }
    // then submit to server
    const params = {
      docType: 'BrokerUpload',
      docName: 'Submission File',
      fileID: this.props.match.params.id,
      fileName: this.state.fileArr[0].fileName,
      fileContent: combinedFiles,
    };

    fetch('/fileupload', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.text())
      .then(data => {
        // error check
        if (!data.startsWith('Success')) {
          // show error message
          this.setState({
            isUploading: false,
            successMessage: '',
            errorMessage: data,
          });
        } else {
          // notify for successful upload
          this.setState({
            isUploading: false,
            fileArr: [],
            successMessage: 'File(s) Successfully Uploaded!',
          });
        }
      });
  }

  combinePDFBuffers(file1, file2) {
    return file1 + '$$$$$' + file2;
  }

  InputContent() {
    if (this.state.isSubmitting === true) {
      return <div className="UploadFile">Loading File(s)...</div>;
    } else {
      return (
        <div>
          <i
            className="bi bi-cloud-upload text-muted"
            style={{ fontSize: '4rem' }}
          ></i>
          <p className="mb-1 text-muted">
            Drag and Drop .PDF Files or Click Below to Upload
          </p>
          <small className="text-muted">
            (The System Will Only Accept Up to 20 Mb of Files at a Time)
          </small>
        </div>
      );
    }
  }

  handleChangeStatus({ file, remove }, status) {
    if (file && file.size > MAX_FILE_SIZE) {
      this.setState({ errorMessage: 'File Size Cannot Exceed 20 Mb!', fileArr: [], isSubmitting: false });
    }
    if (status === 'preparing') {
      this.setState({ isSubmitting: true });
    } else if (status === 'done') {
      const reader = new FileReader();
      var fileToUpload;
      reader.readAsArrayBuffer(file);
      reader.onload = e => {
        fileToUpload = e.target.result;
      };
      reader.onloadend = () => {
        var uintarr = base64ArrayBuffer(fileToUpload);
        this.state.fileArr.push({
          fileUIntArr: uintarr,
          fileName: file.name,
          conditionID: this.state.conditionID,
          size: file.size,
        });
        remove();
        setTimeout(() => {
          this.setState({ isSubmitting: false });
        }, 2000);
      };
    }
  }

  render() {
    const loanId = this.props.match.params.id;
    var loanDataDisplay = [];
    var contactDataDisplay = [];
    var loanData = {};
    var loanContacts = {};

    if (this.state.registeredData) {
      loanData = this.state.registeredData.data;
      loanContacts = this.state.registeredData.contacts;

      // loan data
      var i = 0;
      for (i = 0; i < loanData.length; i++) {
        loanDataDisplay.push(
          this.GetDisplayRow(loanData[i].title, loanData[i].value)
        );
      }

      // loan contacts
      for (i = 0; i < loanContacts.length; i++) {
        contactDataDisplay.push(
          this.GetContactRow(
            loanContacts[i].role,
            loanContacts[i].name,
            loanContacts[i].email,
            loanContacts[i].phone,
            loanContacts[i].fileCount
          )
        );
      }
    }

    if (!this.state.loan) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row">
          <h1 className="h4 text-uppercase">
            submission
            <button
              className="btn btn-text dropdown-toggle"
              style={{ marginLeft: '5px' }}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-list"></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <p className="dropdown-item mb-0 disabled">Recent Files</p>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {this.state.searchLog.length > 0 ? (
                <>
                  {this.state.searchLog.map(file => (
                    <li key={file.encodedFileID}>
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          this.props.history.push(
                            '/pipeline/submission/' + file.encodedFileID
                          );
                          this.PageLoad(file.encodedFileID);
                        }}
                      >
                        {file.encodedFileID} ({file.borrower})
                      </span>
                    </li>
                  ))}
                </>
              ) : (
                <li>
                  <p className="dropdown-item mb-0 disabled">No Recent Files</p>
                </li>
              )}
            </ul>
          </h1>
          {this.state.loan.submittedDate !== '---' ? (
            <p className="col-12 text-left">
              File uploads are disabled once the loan is ready for underwriting
            </p>
          ) : (
            <div className="text-center">
              <Dropzone
                maxFiles="100"
                accept=".pdf"
                onChangeStatus={this.handleChangeStatus.bind(this)}
                LayoutComponent={Layout}
                inputContent={this.InputContent.bind(this)}
                styles={{
                  dropzone: {
                    overflow: 'hidden',
                    border: '2px dashed lightgray',
                    height: '250px',
                    borderRadius: '0.25rem',
                  },
                  dropzoneReject: {
                    borderColor: 'red',
                    backgroundColor: '#DAA',
                  },
                  inputLabel: (_, extra) =>
                    extra.reject ? { color: 'red' } : {},
                }}
              />
              <br/>
              <FilesContainer>
                {this.state.fileArr.map(({ fileName }) => (
                  <div key={fileName}>
                    <img
                      src="/images/arrow.png"
                      width="10px"
                      height="10px"
                      alt=""
                    />
                    &nbsp;
                    {fileName}
                  </div>
                ))}
              </FilesContainer>
              {this.state.fileArr.length > 0 && (
                <div>
                  <button
                    className="btn btn-light"
                    onClick={() => this.setState({ fileArr: [] })}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    id="findFileBtn"
                    disabled={this.state.isUploading}
                    onClick={() => this.submitFileToServer()}
                    style={{marginLeft:'5px'}}
                  >
                    Submit File(s)
                  </button>
                </div>
              )}

              {this.state.successMessage && (
                <Toast
                  type="success"
                  title={this.state.successMessage}
                  onClose={() => this.setState({ successMessage: '' })}
                />
              )}
              {this.state.errorMessage && (
                <Toast
                  type="danger"
                  title={this.state.errorMessage}
                  onClose={() => this.setState({ errorMessage: '' })}
                />
              )}
              <ModalWait
                title="Uploading Files. Please Wait!"
                show={this.state.isUploading}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase">
              registered loan file #: {loanId}
            </h1>
          </div>
          <div className="col-12">
            {!this.state.registeredData ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <img
                  src="/images/spinner.gif"
                  alt=""
                  height="64px"
                  width="64px"
                />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-12">
                    <p className="border-bottom">Loan Information</p>
                  </div>
                </div>
                <div className="row">
                  {loanDataDisplay.map(content => content)}
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="border-bottom">Contacts</p>
                  </div>
                  <div className="grid">
                    {contactDataDisplay.map(content => content)}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Submission);
