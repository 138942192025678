import {
  Body,
  Verify,
  Email,
  AutoCheck,
  SignOff,
  FindLabelFromName,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class PostFundingChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      borrowers: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        changeInShipping: { checked: false },
        changeShipped: { checked: false },
        changeInPostFunding: { checked: false },
        changeClosed: { checked: false },
        fssEmail: { checked: false },
        finalSS: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],
      fssTemplate: [],
      fssEmailLog: [],
      fscdTemplate: [],
      fscdEmailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getFundingData();
  }

  async getFundingData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'PFD' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getpostfundingfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var fundingFields = (await response).data;
    this.setState({
      fileData: resultData,
      checkList: checklistData,
      fssTemplate: fundingFields.fssTemplate,
      fssEmailLog: fundingFields.fssEmailLog,
      fscdTemplate: fundingFields.fscdTemplate,
      fscdEmailLog: fundingFields.fscdEmailLog,
      isLoading: false,
    });
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'PFD-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if(!checked) { 
      checkList.complete.checked = false;
      this.setChecklistField('complete', false); 
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'In Shipping':
          file.inShippingDate = todaysDateYYYY();
          break;
        case 'Shipped':
          file.shippedDate = todaysDateYYYY();
          break;
        case 'In Post-Funding':
          file.inPostFundingDate = todaysDateYYYY();
          break;
        case 'Closed':
          file.closedDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }
  
  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') 
        continue;
      visibleCount++;
      if(prop === 'changeInShipping') {
        if(this.state.fileData.inShippingDate !== '---') { checkedCount++; }
        continue;
      }
      if(prop === 'changeShipped') {
        if(this.state.fileData.shippedDate !== '---') { checkedCount++; }
        continue;
      }
      if(prop === 'changeInPostFunding') {
        if(this.state.fileData.inPostFundingDate !== '---') { checkedCount++; }
        continue;
      }
      if(prop === 'changeClosed') {
        if(this.state.fileData.closedDate !== '---') { checkedCount++; }
        continue;
      }
      if (Object.prototype.hasOwnProperty.call(checklistData, prop) && checklistData[prop].checked) {
        checkedCount++;
      }
    }
    var completePercent = this.state.fileData.completePercent?.split("PFD")[1].split('(')[1].split(')')[0];
    if(completePercent.toString() !== Math.round(checkedCount / visibleCount * 100).toString()) {
      this.state.fileData.completePercent = this.state.fileData.completePercent?.split("PFD")[0] + "PFD (" +
        Math.round(checkedCount / visibleCount * 100).toString() + ")" + 
        this.state.fileData.completePercent?.split("PFD")[1].substring(this.state.fileData.completePercent?.split("PFD")[1].indexOf(")")+1);
      this.props.updatePercent('PFD', Math.round(checkedCount / visibleCount * 100).toString());
    }
    if (checkedCount < visibleCount) {
      if(this.state.checkList.complete.checked == true) {
        this.state.checkList.complete.checked = false;
        this.setChecklistField('complete', false);
        await this.delay(500);
        this.props.updatePercent('PFD', Math.round((checkedCount / visibleCount) * 100).toString());
      }
      return false;
    }
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('PFD', 'complete');
  }

  async ClearAll() {
    await axios.get('/ClearCheckboxes', {
      params: { pFileID: this.state.activeFile, checklist: 'PFD' },
    });
    this.setState({ showClearAllModal: false });
    this.getFundingData();
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Post Funding Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">
          Post Funding Checklist
          {isAdmin() && (
            <button className="btn btn-outline-secondary"
              style={{
                fontSize: '12px',
                height: '24px',
                padding: '2px',
                width: '70px',
                marginLeft: '15px',
                pointerEvents: 'auto',
                position: 'relative',
                top: '-2px'
              }}
              onClick={() => this.setState({showClearAllModal: true}) }
            > 
              Clear All
            </button>
          )}
        </h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Change Status (In Shipping) */}
          <ChecklistCard checked={this.state.fileData.inShippingDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeInShipping"
                  name="changeInShipping"
                  disabled={true}
                  checked={this.state.fileData.inShippingDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeInShipping"
                >
                  In Shipping
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'In Shipping'" />
                {this.state.fileData.inShippingDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['in shipping'],
                      }}
                    >
                      In Shipping
                    </span>
                    {this.state.fileData.inShippingDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={this.state.changingState}
                      onClick={() => this.setLoanStatus('In Shipping')}
                    >
                      {this.state.changingState === 'In Shipping' ? '...' : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.inShippingDate !== '---'}
                tip="In Shipping Date Not Found"
              />
            }
          />

          {/* Final Settlement Statment Email */}
          <ChecklistCard checked={checkList.fssEmail.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="fssEmail"
                  name="fssEmail"
                  checked={checkList.fssEmail.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="fssEmail">
                  Send Email
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Email />
                  Final Settlement Statement
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.fssTemplate,
                        emailLog: this.state.fssEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.fssEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto'
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.fssEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
                <div className="hstack">
                  <Email />
                  <div>
                    Final Settlement Statement
                    <br/>
                    And Seller's CD
                  </div>
                  <button
                    className="btn btn-sm btn-outline-primary ms-2"
                    onClick={() => {
                      this.setState({
                        showEmailTemplate: true,
                        emailTemplate: this.state.fscdTemplate,
                        emailLog: this.state.fscdEmailLog,
                      });
                    }}
                  >
                    Preview
                  </button>
                  {this.state.fscdEmailLog.length > 0 && (
                    <button
                      className="btn"
                      type="button"
                      style={{
                        height: '24px',
                        maxWidth: '24px',
                        marginLeft: '10px',
                        pointerEvents: 'auto'
                      }}
                      onClick={() =>
                        this.setState({
                          showEmailLog: true,
                          emailLog: this.state.fscdEmailLog,
                        })
                      }
                    >
                      <i
                        className="bi bi-envelope-check"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          position: 'relative',
                          top: '-10px',
                          left: '-10px',
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            }
            alert={
              (checkList.fssEmail.checked && (
                <SignOff
                  user={checkList.fssEmail.userName}
                  date={checkList.fssEmail.timestamp}
                />
              ))
            }
          />

          {/* Final SS Received */}
          <ChecklistCard checked={checkList.finalSS.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="finalSS"
                  name="finalSS"
                  checked={checkList.finalSS.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="finalSS">
                  Final SS
                </label>
              </div>
            }
            body={
              <div>
                <Verify />
                Receipt of Final Settlement Statement
              </div>
            }
            alert={
              (checkList.finalSS.checked && (
                <SignOff
                  user={checkList.finalSS.userName}
                  date={checkList.finalSS.timestamp}
                />
              ))
            }
          />

          {/* Change Status (Shipped) */}
          <ChecklistCard checked={this.state.fileData.shippedDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeShipped"
                  name="changeShipped"
                  disabled={true}
                  checked={this.state.fileData.shippedDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeShipped"
                >
                  Shipped
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Shipped'" />
                {this.state.fileData.shippedDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['shipped'],
                      }}
                    >
                      Shipped
                    </span>
                    {this.state.fileData.shippedDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={this.state.changingState}
                      onClick={() => this.setLoanStatus('Shipped')}
                    >
                      {this.state.changingState === 'Shipped' ? '...' : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.shippedDate !== '---'}
                tip="Shipped Date Not Found"
              />
            }
          />

          {/* Change Status (In Post-Funding) */}
          <ChecklistCard checked={this.state.fileData.inPostFundingDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeInPostFunding"
                  name="changeInPostFunding"
                  disabled={true}
                  checked={this.state.fileData.inPostFundingDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeInPostFunding"
                >
                  In Post-Funding
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'In Post-Funding'" />
                {this.state.fileData.inPostFundingDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['in post-funding'],
                      }}
                    >
                      In Post-Funding
                    </span>
                    {this.state.fileData.inPostFundingDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={this.state.changingState}
                      onClick={() => this.setLoanStatus('In Post-Funding')}
                    >
                      {this.state.changingState === 'In Post-Funding' ? '...' : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.inPostFundingDate !== '---'}
                tip="In Post-Funding Date Not Found"
              />
            }
          />

          {/* Change Status (Closed) */}
          <ChecklistCard checked={this.state.fileData.closedDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeClosed"
                  name="changeClosed"
                  disabled={true}
                  checked={this.state.fileData.closedDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeClosed"
                >
                  Closed
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Closed'" />
                {this.state.fileData.closedDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['closed'],
                      }}
                    >
                      Closed
                    </span>
                    {this.state.fileData.closedDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={this.state.changingState}
                      onClick={() => this.setLoanStatus('Closed')}
                    >
                      {this.state.changingState === 'Closed' ? '...' : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.closedDate !== '---'}
                tip="Closed Date Not Found"
              />
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
          <ModalConfirm
            show={this.state.showClearAllModal}
            onAccept={() => this.ClearAll()}
            onDecline={() => this.setState({ showClearAllModal: false })}
          >
            <div style={{ fontSize: '16px', textAlign: 'center' }}>
              <b>Are You Sure You Want to Clear <u>ALL</u> <br/>
              Checkbox Fields For This Checklist?</b>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
