import {
  Body,
  Task,
  SignOff,
  FindLabelFromName,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS } from '../../helpers/constants';
import { getPropertyType, isAdmin } from '../../helpers/utils';
import Toast from '../Toast';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class ServicingChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        goodbyeLetter: { checked: false },
        complete: { checked: false },
      },
    };

    this.getServicingData();
  }

  async getServicingData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    /* response = axios.get('/getservicingfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    }); 
    
    var servicingFields = (await response).data;
    */

    var statelist = {
      fileData: resultData,
      isLoading: false,
    };

    // get checklist data
    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'SRV' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    statelist['checkList'] = checklistData;
    this.setState(statelist);
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'SRV-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if(!checked) { 
      checkList.complete.checked = false;
      this.setChecklistField('complete', false); 
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'SRV',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2], // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]],
          [fieldsToUpdate[i][4] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }
  
  delay(milliseconds) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  async allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (prop === 'complete') 
        continue;
      visibleCount++;
      if (Object.prototype.hasOwnProperty.call(checklistData, prop) && checklistData[prop].checked) {
        checkedCount++;
      }
    }
    var completePercent = this.state.fileData.completePercent?.split("SRV")[1].split('(')[1].split(')')[0];
    if(completePercent.toString() !== Math.round(checkedCount / visibleCount * 100).toString()) {
      this.state.fileData.completePercent = this.state.fileData.completePercent?.split("SRV")[0] + "SRV (" +
        Math.round(checkedCount / visibleCount * 100).toString() + ")" + 
        this.state.fileData.completePercent?.split("SRV")[1].substring(this.state.fileData.completePercent?.split("SRV")[1].indexOf(")")+1);
      this.props.updatePercent('SRV', Math.round(checkedCount / visibleCount * 100).toString());
    }
    if (checkedCount < visibleCount) {
      if(this.state.checkList.complete.checked == true) {
        this.state.checkList.complete.checked = false;
        this.setChecklistField('complete', false);
        await this.delay(500);
        this.props.updatePercent('SRV', Math.round((checkedCount / visibleCount) * 100).toString());
      }
      return false;
    }
    return true;
  }

  markComplete() {
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('SRV', 'complete');
  }

  async ClearAll() {
    await axios.get('/ClearCheckboxes', {
      params: { pFileID: this.state.activeFile, checklist: 'SRV' },
    });
    this.setState({ showClearAllModal: false });
    this.getServicingData();
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Servicing Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">
          Servicing Checklist
          {isAdmin() && (
            <button className="btn btn-outline-secondary"
              style={{
                fontSize: '12px',
                height: '24px',
                padding: '2px',
                width: '70px',
                marginLeft: '15px',
                pointerEvents: 'auto',
                position: 'relative',
                top: '-2px'
              }}
              onClick={() => this.setState({showClearAllModal: true}) }
            > 
              Clear All
            </button>
          )}
        </h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Goodbye Letter */}
          <ChecklistCard checked={checkList.goodbyeLetter.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="goodbyeLetter"
                  name="goodbyeLetter"
                  checked={checkList.goodbyeLetter.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="goodbyeLetter">
                  Goodbye Letter
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task />
                  Create and Mail a Goodbye Letter
                </div>
                <div>
                  <Task />
                  Save the Letter Into Byte
                </div>
              </div>
            }
            alert={
              (checkList.goodbyeLetter.checked && (
                <SignOff
                  user={checkList.goodbyeLetter.userName}
                  date={checkList.goodbyeLetter.timestamp}
                />
              ))
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
          <ModalConfirm
            show={this.state.showClearAllModal}
            onAccept={() => this.ClearAll()}
            onDecline={() => this.setState({ showClearAllModal: false })}
          >
            <div style={{ fontSize: '16px', textAlign: 'center' }}>
              <b>Are You Sure You Want to Clear <u>ALL</u> <br/>
              Checkbox Fields For This Checklist?</b>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
