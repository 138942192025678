import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import LoginState from '../components/LoginState';
import { STATUS_COLORS } from '../helpers/constants';
import axios from 'axios';

const Alert = ({ text }) => {
  return (
    <div
      className="bg-light rounded w-100 h-100 d-flex justify-content-center align-items-center p-5"
      role="alert"
    >
      <div className="d-flex gap-1 justify-content-center align-items-center w-75">
        <i className="bi bi-check2-circle me-2 text-success fs-4"></i>
        <h1 className="text-secondary fs-5 mb-1">{text}</h1>
      </div>
    </div>
  );
};

const InfoAlert = ({ text }) => {
  return (
    <div className="bg-light rounded p-2 d-flex gap-2 align-items-center justify-content-center mb-2">
      <i className="bi bi-info-circle fs-5 text-secondary"></i>
      <p className="text-secondary mb-0">{text}</p>
    </div>
  );
};

class LenderPanel extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();

    let currentTab = 'New Loans'; // default value
    switch (this.props.match.params.tab) {
      case 'newloans':
        currentTab = 'New Loans';
        break;
      case 'readyuw':
        currentTab = 'Ready For UW';
        break;
      case 'uwconditions':
        currentTab = 'UW Conditions';
        break;
      case 'fundingconditions':
        currentTab = 'Funding Conditions';
        break;
      case 'tridconditions':
        currentTab = 'TRID Conditions';
        break;
      case 'qcconditions':
        currentTab = 'QC Conditions';
        break;
      case 'appraisals':
        currentTab = 'Appraisals';
        break;
      case 'ratelock':
        currentTab = 'Rate Lock';
        break;
      case 'change-in-circumstance':
        currentTab = 'CIC';
        break;
      case 'docrequest':
        currentTab = 'Doc Request';
        break;
      case 'docin':
        currentTab = 'Doc In';
        break;
      case 'comments':
        currentTab = 'Condition Comments';
        break;
      default:
        return;
    }

    this.state = {
      retrievedData: false,
      notificationCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      activeTab: currentTab,
      retrievedTab: '',
      successMessage: '',
      notificationData: [],
    };

    // if not in top level org, redirect
    if (LoginState.userOrg !== '1000001' && LoginState.userId !== 1000009) {
      this.props.history.push('/');
    }

    // get notification count
    this.getNotificationCount();

    // get options before page loads
    this.getNotifications();
  }

  componentDidUpdate() {
    let currentTab = '';

    switch (this.props.match.params.tab) {
      case 'newloans':
        currentTab = 'New Loans';
        break;
      case 'readyuw':
        currentTab = 'Ready For UW';
        break;
      case 'uwconditions':
        currentTab = 'UW Conditions';
        break;
      case 'fundingconditions':
        currentTab = 'Funding Conditions';
        break;
      case 'tridconditions':
        currentTab = 'TRID Conditions';
        break;
      case 'qcconditions':
        currentTab = 'QC Conditions';
        break;
      case 'appraisals':
        currentTab = 'Appraisals';
        break;
      case 'ratelock':
        currentTab = 'Rate Lock';
        break;
      case 'change-in-circumstance':
        currentTab = 'CIC';
        break;
      case 'docrequest':
        currentTab = 'Doc Request';
        break;
      case 'docin':
        currentTab = 'Doc In';
        break;
      case 'comments':
        currentTab = 'Condition Comments';
        break;
      default:
        return;
    }
    if (this.state.activeTab !== currentTab)
      this.setState({ activeTab: currentTab });
  }

  async getNotificationCount() {
    var response = axios.get('/getnotificationcounts', {
      params: { pUserID: LoginState.userId },
    });

    // the response.data will contain the values returned
    try {
      var resultData = (await response).data;
      this.setState({ notificationCounts: resultData });
    } catch {
      this.setState({
        notificationCounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      });
    }
  }

  async getNotifications() {
    var response;

    if (this.state.activeTab === 'New Loans') {
      response = axios.get('/getnewloannotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'Ready For UW') {
      response = axios.get('/getloansubmittednotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'UW Conditions') {
      response = axios.get('/getuwconditionnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'Funding Conditions') {
      response = axios.get('/getfundingconditionnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'TRID Conditions') {
      response = axios.get('/gettridconditionnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'QC Conditions') {
      response = axios.get('/getqcconditionnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'Appraisals') {
      response = axios.get('/getappraisalnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'Rate Lock') {
      response = axios.get('/getratelocknotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'CIC') {
      response = axios.get('/getcircumstancenotifications', {
        params: {
          pUserID: LoginState.userId,
          pFileDataID: '',
          pRequestDate: '',
          pStatus: '',
          pChangeRequested: '',
          pUserName: LoginState.userName,
          pRole: '',
        },
      });
    }

    if (this.state.activeTab === 'Doc Request') {
      response = axios.get('/getdocrequestnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'Doc In') {
      response = axios.get('/getescrownotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    if (this.state.activeTab === 'Condition Comments') {
      response = axios.get('/getcommentnotifications', {
        params: { pUserID: LoginState.userId },
      });
    }

    // the response.data will contain the values returned
    try {
      var resultData = (await response).data;
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: resultData,
      });
    } catch {
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: [],
      });
    }
  }

  renderContent = () => {
    const { activeTab, retrievedTab } = this.state;

    if (activeTab !== retrievedTab) {
      this.getNotifications();
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary my-4" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
    if (activeTab === 'New Loans') return this.ShowNewLoans();
    if (activeTab === 'Ready For UW') return this.ShowLoansSubmitted();
    if (activeTab === 'UW Conditions') return this.ShowUWConditions();
    if (activeTab === 'Funding Conditions') return this.ShowFundingConditions();
    if (activeTab === 'TRID Conditions') return this.ShowTRIDConditions();
    if (activeTab === 'QC Conditions') return this.ShowQcConditions();
    if (activeTab === 'Appraisals') return this.ShowAppraisals();
    if (activeTab === 'Rate Lock') return this.ShowRateLock();
    if (activeTab === 'CIC') return this.ShowCircumstance();
    if (activeTab === 'Doc Request') return this.ShowDocRequest();
    if (activeTab === 'Doc In') return this.ShowDocIn();
    if (activeTab === 'Condition Comments') return this.ShowConditionComments();
  };

  ShowNewLoans() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No New Loan Files" />;
    }
    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="New loan files that have not been reviewed" />
        <table className="table rounded bg-white py-2">
          <thead>
            <tr>
              <th>Registered Date ↓</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Loan Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/submission/${file.encodedFileID}`}>
                    {file.loanStatus.includes('AUS')
                      ? 'AUS Request'
                      : file.registeredDate}
                  </Link>
                </td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/sub-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">
                  <span
                    className={`badge fw-normal pill-${
                      STATUS_COLORS[file.loanStatus.toLowerCase()]
                    }`}
                  >
                    {file.loanStatus}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowLoansSubmitted() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Loan Files That Are Ready For Underwriting" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Loan files that are ready for underwriting" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Registered Date ↓</th>
              <th>Submitted Date</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Loan Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/loan-summary/${file.encodedFileID}`}>
                    {file.registeredDate}
                  </Link>
                </td>
                <td>{file.submittedDate}</td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/sub-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">
                  <span
                    className={`badge fw-normal pill-${
                      STATUS_COLORS[file.loanStatus.toLowerCase()]
                    }`}
                  >
                    {file.loanStatus}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowUWConditions() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No UW Condition Files" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="UW condition files that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Conditions</th>
              <th>Uploaded</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Underwriter 1</th>
              <th className="text-center">Underwriter 2</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/conditions/uw-${file.encodedFileID}`}>
                    {file.conditionCount} Uploaded {file.bulkID && '(*)'}
                  </Link>
                </td>
                <td>{file.uploadedTimestamp}</td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/uwr-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">{file.underwriter1}</td>
                <td className="text-center">{file.underwriter2}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowFundingConditions() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Funding Conditions Files" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Funding condition files that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Conditions</th>
              <th className="text-center">Funding Date ↓</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Funder</th>
              <th className="text-center">Underwriter 1</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/conditions/fd-${file.encodedFileID}`}>
                    {file.conditionCount} Uploaded {file.bulkID && '(*)'}
                  </Link>
                </td>
                <td className="text-center">{file.closingDate}</td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/fnd-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">{file.funder}</td>
                <td className="text-center">{file.underwriter1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowTRIDConditions() {
    /* compliance conditions */
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Compliance Condition Files" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Compliance condition files that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Conditions</th>
              <th className="text-center">Closing Date ↓</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Compliance</th>
              <th className="text-center">Underwriter 1</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/conditions/cp-${file.encodedFileID}`}>
                    {file.conditionCount} Uploaded {file.bulkID && '(*)'}
                  </Link>
                </td>
                <td className="text-center">{file.closingDate}</td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/cmp-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">{file.compliance}</td>
                <td className="text-center">{file.underwriter1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowQcConditions() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No QC Condition Files" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="QC condition files that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Conditions</th>
              <th className="text-center">Closing Date ↓</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">QC</th>
              <th className="text-center">Underwriter 1</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/conditions/qc-${file.encodedFileID}`}>
                    {file.conditionCount} Pending {file.bulkID && '(*)'}
                  </Link>
                </td>
                <td className="text-center">{file.closingDate}</td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/qct-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">{file.qc}</td>
                <td className="text-center">{file.underwriter1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowAppraisals() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Appraisal Reports Needing Review" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="This Feature Is Under Development - The Content Below Is Test Data - Please Disregard" />
        {/* <InfoAlert text="Appraisal Reports That Have Not Been Reviewed" /> */}
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Appraisal ID</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th>Underwriter 1</th>
              <th>Underwriter 2</th>
              <th>Last Updated</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.appraisalID}>
                <td>
                  <Link to={`../details/apr-${file.encodedFileID}`}>
                    {file.appraisalID}
                  </Link>
                </td>
                <td>{file.encodedFileID}</td>
                <td>{file.borrower}</td>
                <td>{file.underwriter1}</td>
                <td>{file.underwriter2}</td>
                <td>{file.lastUpdated}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowRateLock() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Rate Lock Requests" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Rate Lock requests that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Request Date ↓</th>
              <th>Borrower</th>
              <th>File Name</th>
              <th>Requested By</th>
              <th>Rate Requested</th>
              <th>Lock Days</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/lock/${file.encodedFileID}`}>
                    {file.requestDate}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/lck-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.requestedBy}</td>
                <td>{file.rateRequested}</td>
                <td>{file.lockDays}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowCircumstance() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Change in Circumstance Files" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Change in Circumstance files that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Request Date ↓</th>
              <th>File Name</th>
              <th className="text-center">Changed Fields</th>
              <th>Borrower</th>
              <th className="text-center">Underwriter</th>
              <th className="text-center">Compliance</th>
              <th className="text-center">Lock Desk</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.requestDate}>
                <td>
                  <Link
                    to={`../pipeline/change-in-circumstance/${file.encodedFileID}`}
                  >
                    {file.requestDate}
                  </Link>
                </td>
                <td>{file.fileName}</td>
                <td className="text-center">{file.numChanges}</td>
                <td>{file.borrower}</td>
                <td className="text-center">
                  {file.underwriter1}{' '}
                  {file.underwriter2 && ' / ' + file.underwriter2}{' '}
                  {file.uwCount === 0 ? (
                    <span className="ms-2 badge rounded-pill rounded text-bg-success">
                      0
                    </span>
                  ) : (
                    <span className="ms-2 badge rounded-pill rounded text-bg-danger">
                      {file.uwCount}
                    </span>
                  )}
                </td>
                <td className="text-center">
                  {file.compliance}{' '}
                  {file.compCount === 0 ? (
                    <span className="ms-2 badge rounded-pill rounded text-bg-success">
                      0
                    </span>
                  ) : (
                    <span className="ms-2 badge rounded-pill rounded text-bg-danger">
                      {file.compCount}
                    </span>
                  )}
                </td>
                <td className="text-center">
                  {file.lockDesk}{' '}
                  {file.lockCount === 0 ? (
                    <span className="ms-2 badge rounded-pill rounded text-bg-success">
                      0
                    </span>
                  ) : (
                    <span className="ms-2 badge rounded-pill rounded text-bg-danger">
                      {file.lockCount}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowDocRequest() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Doc Request Files" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Doc Request files that have not been reviewed" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Request Date ↓</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Closer</th>
              <th className="text-center">Clear Request</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>
                  <Link to={`../pipeline/closing/${file.encodedFileID}`}>
                    {file.requestDate}
                  </Link>
                </td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/clo-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">{file.docCloser}</td>
                <td className="text-center">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() =>
                      this.ClearDocRequest(file.fileDataID, file.fileName)
                    }
                  >
                    Clear
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowDocIn() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Escrow Documents Submitted With Condition #250" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <InfoAlert text="Escrow documents submitted with condition #250" />
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>Doc In Date ↓</th>
              <th>Conditions</th>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Funder</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(file => (
              <tr key={file.fileDataID}>
                <td>{file.docInDate}</td>
                <td>
                  <Link to={`../pipeline/conditions/di-${file.encodedFileID}`}>
                    {file.conditionCount} Uploaded
                  </Link>
                </td>
                <td>
                  <Link
                    color="primary500"
                    to={`../details/ctc-${file.encodedFileID}`}
                  >
                    {file.fileName}
                  </Link>
                </td>
                <td>{file.borrower}</td>
                <td className="text-center">{file.funder}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  ShowConditionComments() {
    if (
      !this.state.notificationData ||
      this.state.notificationData.length === 0
    ) {
      return <Alert text="No Condition Comments" />;
    }

    return (
      <div className="table-responsive fixed-header">
        <table className="table rounded bg-white pt-2">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Borrower</th>
              <th className="text-center">Underwriter 1</th>
              <th className="text-center">Underwriter 2</th>
              <th className="text-center">Mark Cleared</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.notificationData.map(comment => (
              <tr key={comment.fileDataID}>
                <td>
                  <Link
                    to={`../pipeline/conditions/ac-${comment.encodedFileID}`}
                  >
                    {comment.encodedFileID}
                  </Link>
                </td>
                <td>{comment.borrower}</td>
                <td className="text-center">{comment.underwriter1}</td>
                <td className="text-center">{comment.underwriter2}</td>
                <td className="text-center">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() =>
                      this.ClearComments(
                        comment.fileDataID,
                        comment.encodedFileID
                      )
                    }
                  >
                    Clear
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  async ClearDocRequest(fileDataID, fileName) {
    var response = axios.get('/getdocrequestnotifications', {
      params: {
        pUserID: LoginState.userId,
        pUserName: LoginState.userName,
        pfileDataID: fileDataID,
      },
    });

    try {
      var resultData = (await response).data;
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: resultData,
        successMessage: 'Doc Request Cleared (FileID: ' + fileName + ')',
      });
    } catch {
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: [],
      });
    }
  }

  async ClearComments(fileDataID, encodedFileID) {
    var response = axios.get('/getcommentnotifications', {
      params: {
        pfileDataID: fileDataID,
      },
    });

    try {
      var resultData = (await response).data;
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: resultData,
        successMessage:
          'Comments Marked Cleared (FileID: ' + encodedFileID + ')',
      });
    } catch {
      this.setState({
        retrievedData: true,
        retrievedTab: this.state.activeTab,
        notificationData: [],
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase">Notifications</h1>
          </div>
          <ul class="nav nav-tabs pe-0">
            {/* <ul className="nav nav-pills flex-nowrap overflow-scroll mb-2 mb-lg-0 flex-lg-column notifications-nav"> */}
            <Link
              to="./newloans"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('newloans')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Registered
              </li>
              {this.state.notificationCounts[0] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[0]}
                </span>
              )}
            </Link>
            <Link
              to="./readyuw"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('readyuw') ? 'active' : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Submitted
              </li>
              {this.state.notificationCounts[1] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[1]}
                </span>
              )}
            </Link>
            <Link
              to="./uwconditions"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('uwconditions')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Underwriting
              </li>
              {this.state.notificationCounts[2] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[2]}
                </span>
              )}
            </Link>
            <Link
              to="./fundingconditions"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('fundingconditions')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Funding
              </li>
              {this.state.notificationCounts[3] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[3]}
                </span>
              )}
            </Link>
            <Link
              to="./tridconditions"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('tridconditions')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Compliance
              </li>
              {this.state.notificationCounts[4] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[4]}
                </span>
              )}
            </Link>
            <Link
              to="./qcconditions"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('qcconditions')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                QC
              </li>
              {this.state.notificationCounts[5] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[5]}
                </span>
              )}
            </Link>
            <Link
              to="./appraisals"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('appraisals')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Appraisals
              </li>
              {this.state.notificationCounts[6] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[6]}
                </span>
              )}
            </Link>
            <Link
              to="./ratelock"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('ratelock')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Rate Lock
              </li>
              {this.state.notificationCounts[7] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[7]}
                </span>
              )}
            </Link>
            <Link
              to="./change-in-circumstance"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('change-in-circumstance')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                CIC
              </li>
              {this.state.notificationCounts[8] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[8]}
                </span>
              )}
            </Link>
            <Link
              to="./docrequest"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('docrequest')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Doc Request
              </li>
              {this.state.notificationCounts[9] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[9]}
                </span>
              )}
            </Link>
            <Link
              to="./docin"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('docin') ? 'active' : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Doc In
              </li>
              {this.state.notificationCounts[10] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[10]}
                </span>
              )}
            </Link>
            <Link
              to="./comments"
              className={`nav-link px-2 d-flex justify-content-between align-items-center text-nowrap position-relative ${
                this.props.location.pathname.includes('comments')
                  ? 'active'
                  : ''
              }`}
            >
              <li aria-current="page" className="text-center w-100">
                Comments
              </li>
              {this.state.notificationCounts[11] > 0 && (
                <span
                  className="ms-1 badge rounded-pill rounded text-bg-danger"
                  style={{ fontSize: '10px' }}
                >
                  {this.state.notificationCounts[11]}
                </span>
              )}
            </Link>
          </ul>
          <div className="rounded-bottom bg-white p-4 border border-top-0">
            <div>{this.renderContent()}</div>
            {this.state.successMessage && (
              <div
                className="toast align-items-center"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="d-flex">
                  <div className="toast-body">{this.state.successMessage}</div>
                  <button
                    type="button"
                    className="btn-close me-2 m-auto"
                    onClick={() => this.setState({ successMessage: null })}
                    data-bs-dismiss="toast"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LenderPanel);
